/*  "LAYOUT" STYLES */
.air__layout {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
        -ms-flex: auto;
            flex: auto;
    min-height: 100vh;
    min-width: 0;
}

.air__layout.air__layout--hasSider {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
}

.air__layout__content {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
        -ms-flex: auto;
            flex: auto;
    min-height: 0;
}

.air__layout__footer {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}

.air__layout__header {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}

.air__layout--fixedHeader .air__layout__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.air__layout--squaredBorders .card {
    -webkit-border-radius: 0 !important;
            border-radius: 0 !important;
}

.air__layout--appMaxWidth > .air__layout {
    max-width: 1420px;
    margin: 0 auto;
}

.air__layout--contentNoMaxWidth .air__utils__content,
.air__layout--contentNoMaxWidth .air__footer {
    max-width: none;
}

.air__layout--borderless .card {
    border: none !important;
}

.air__layout--grayBackground {
    background: #f2f4f8;
}

.air__layout--grayTopbar .air__topbar,
.air__layout--grayTopbar .air__subbar {
    background: #f2f4f8;
}

.air__layout--cardsShadow .card {
    -webkit-box-shadow: 0 4px 10px 0 rgba(22, 21, 55, 0.03), 0 0 10px 0 rgba(22, 21, 55, 0.02);
            box-shadow: 0 4px 10px 0 rgba(22, 21, 55, 0.03), 0 0 10px 0 rgba(22, 21, 55, 0.02);
}

.air__layout--cardsShadow .card .card {
    -webkit-box-shadow: none;
            box-shadow: none;
}
/* TOPBAR */
.air__topbar {
    background: #f9f9f9;
    min-height: 4.26rem;
    border-bottom: 1px solid $border-light;
    padding: 1rem 1.33rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.air__topbar__searchDropdown .dropdown-menu {
    padding-top: 1.33rem;
    padding-left: 1.33rem;
    padding-right: 0.33rem;
    padding-bottom: 1.33rem;
    min-width: 22rem;
}

.air__topbar__search {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.air__topbar__search::after {
    display: none;
}

.air__topbar__searchContainer {
    position: relative;
}

.air__topbar__searchIcon {
    position: absolute;
    color: #c3bedc;
    font-size: 1.13rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 1rem;
}

.air__topbar__searchInput {
    border: 1px solid #e4e9f0;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: #fff;
    padding-left: 3rem;
    padding-right: 1.33rem;
    padding-top: 0.33rem;
    padding-bottom: 0.26rem;
    width: 17.33rem;
}

.air__topbar__status {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 0.33rem 0.46rem;
}
/* MENU LEFT */
.air__menuLeft {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 240px;
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    max-width: 240px;
    min-width: 240px;
    width: 240px;
    background: #161537;
    overflow: hidden;
    position: relative;
}

@media (max-width: 767px) {
    .air__menuLeft {
        z-index: 999;
        top: 0;
        left: 0;
        bottom: 0;
        overflow: visible;
        margin-left: -240px;
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        transition: -webkit-transform 0.2s ease-in-out;
        -o-transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    }
    .air__menuLeft__mobileToggleButton {
        display: block !important;
    }
    .air__menuLeft__toggleButton {
        display: none;
    }
    .air__menu--mobileToggled .air__menuLeft {
        -webkit-transform: translateX(240px);
        -ms-transform: translateX(240px);
        transform: translateX(240px);
    }
}

.air__menuLeft__outer {
    z-index: 999;
    top: 0;
    height: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 240px;
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    max-width: 240px;
    min-width: 240px;
    width: 240px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.air__menuLeft__container {
    padding-bottom: 1.33rem;
}

.air__menuLeft__backdrop {
    z-index: 998;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #161537;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

@media (max-width: 767px) {
    .air__menu--mobileToggled .air__menuLeft__backdrop {
        opacity: 0.2;
        visibility: visible;
    }
}

.air__menuLeft__mobileToggleButton {
    position: absolute;
    right: -2.67rem;
    top: 1.46rem;
    width: 2.66rem;
    height: 2.66rem;
    background: #161537;
    -webkit-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    display: none;
    text-align: center;
    -webkit-box-shadow: 0 4px 38px 0 rgba(22, 21, 55, 0.11), 0 0 21px 0 rgba(22, 21, 55, 0.05);
    box-shadow: 0 4px 38px 0 rgba(22, 21, 55, 0.11), 0 0 21px 0 rgba(22, 21, 55, 0.05);
}

.air__menuLeft__mobileToggleButton span {
    display: inline-block;
    width: 14px;
    height: 2px;
    position: relative;
    background: #fff;
    -webkit-transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -o-transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    top: 5px;
}

.air__menu--mobileToggled .air__menuLeft__mobileToggleButton span {
    background: transparent;
}

.air__menu--mobileToggled .air__menuLeft__mobileToggleButton span:before {
    -webkit-transform: translateY(5px) rotate(45deg);
    -ms-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg);
}

.air__menu--mobileToggled .air__menuLeft__mobileToggleButton span:after {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    -ms-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg);
}

.air__menuLeft__mobileToggleButton span:before, .air__menuLeft__mobileToggleButton span:after {
    content: '';
    display: block;
    position: absolute;
    background: #fff;
    width: 100%;
    height: 2px;
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -o-transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.air__menuLeft__mobileToggleButton span:before {
    top: -5px;
}

.air__menuLeft__mobileToggleButton span:after {
    top: 5px;
}

.air__menuLeft__toggleButton {
    position: absolute;
    top: 0.8rem;
    right: 0.66rem;
    z-index: 2;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.air__menu--toggled .air__menuLeft__toggleButton {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.air__menuLeft__toggleButton:hover span {
    opacity: 1 !important;
}

.air__menuLeft__toggleButton span {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 0.9;
}

.air__menuLeft__toggleButton span:before, .air__menuLeft__toggleButton span:after {
    position: absolute;
    content: '';
    display: block;
    width: 12px;
    height: 3px;
    background: $accent-color-2;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.air__menuLeft__toggleButton span:before {
    top: 14px;
    left: 6px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.air__menuLeft__toggleButton span:after {
    top: 22px;
    left: 6px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.air__menuLeft__toggleButton span:first-child {
    position: relative;
    left: 3px;
}

.air__menuLeft__toggleButton span:last-child {
    position: relative;
    left: 11px;
    opacity: 0.5;
}

.air__menuLeft__logo {
    display: block;
    line-height: 1;
}

.air__menuLeft__logo img {
    float: left;
    margin-top: 0.33rem;
}

.air__menuLeft__logo__name {
    font-weight: 900;
    color: #fff;
    font-size: 21px;
}

.air__menuLeft__logo__descr {
    color: #c3bedc;
    margin-left: 2.66rem;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.air__menuLeft__user {
    display: block;
    padding: 1rem 1.33rem;
    height: 4.8rem;
    line-height: 1.3;
}

.air__menuLeft__user__avatar {
    float: left;
}

.air__menuLeft__user__name {
    margin-left: 4rem;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-top: 0.13rem;
}

.air__menuLeft__user__role {
    margin-left: 4rem;
    color: $accent-color-2;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.air__menuLeft__banner {
    margin: 2.66rem 1.33rem 1.33rem;
    background: $accent-color-2;
    background: -webkit-linear-gradient(45deg, $accent-color-2 0%, #4877e9 100%);
    background: -o-linear-gradient(45deg, $accent-color-2 0%, #4877e9 100%);
    background: linear-gradient(45deg, $accent-color-2 0%, #4877e9 100%);
    -webkit-border-radius: 6px;
    border-radius: 6px;
    padding: 1.33rem;
    color: #fff;
    -webkit-box-shadow: 0 8px 15px -5px black;
    box-shadow: 0 8px 15px -5px black;
}

.air__menuLeft__list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    
}
.air__menuLeft__list .air__menuLeft__list .air__menuLeft__link {
    padding-left: 3.26rem;
}

.air__menuLeft__category {
    text-transform: uppercase;
    color: $accent-color-2;
    letter-spacing: 1px;
    font-size: 0.8rem;
    padding: 0.93rem 1.33rem;
    overflow: hidden;
    position: relative;
}

.air__menuLeft__category:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.33rem;
    right: 1.33rem;
    height: 1px;
    background: #4a436a;
    display: none;
}

.air__menuLeft__item--active > .air__menuLeft__link {
    background: $accent-color-2;
    color: #fff;
}

.air__menuLeft__item--active > .air__menuLeft__link .air__menuLeft__icon {
    color: #fff;
}

.air__menuLeft__submenu > .air__menuLeft__link {
    position: relative;
}

.air__menuLeft__submenu > .air__menuLeft__link:before, .air__menuLeft__submenu > .air__menuLeft__link:after {
    content: '';
    display: block;
    position: absolute;
    top: 1.33rem;
    right: 1.33rem;
    width: 6px;
    height: 2px;
    background: $accent-color-2;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: background 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
}

.air__menuLeft__submenu > .air__menuLeft__link:after {
    right: 1.6rem;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.air__menuLeft__submenu--active {
    background: #100f28;
}

.air__menuLeft__submenu--active > .air__menuLeft__link {
    color: #fff;
}

.air__menuLeft__submenu--active > .air__menuLeft__link:before, .air__menuLeft__submenu--active > .air__menuLeft__link:after {
    background: #fff;
}

.air__menuLeft__submenu--active > .air__menuLeft__link .air__menuLeft__icon {
    color: #fff;
}

.air__menuLeft__submenu .air__menuLeft__list {
    display: none;
    padding-bottom: 0.66rem;
}

.air__menuLeft__link {
    color: $accent-color-3;
    display: block;
    padding: 0.6rem 2.66rem 0.6rem 1.33rem;
    font-size: 1rem;
}

.air__menuLeft__link:hover, .air__menuLeft__link:focus {
    color: #fff;
}

.air__menuLeft__link:hover:before, .air__menuLeft__link:hover:after, .air__menuLeft__link:focus:before, .air__menuLeft__link:focus:after {
    background: #fff;
}

.air__menuLeft__icon {
    display: inline-block;
    width: 1.66rem;
}

@media (min-width: 768px) {
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 80px;
        -ms-flex: 0 0 80px;
        flex: 0 0 80px;
        max-width: 80px;
        min-width: 80px;
        width: 80px;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__outer {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 80px;
        -ms-flex: 0 0 80px;
        flex: 0 0 80px;
        max-width: 80px;
        min-width: 80px;
        width: 80px;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__logo {
        visibility: hidden;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__toggleButton {
        right: 1.33rem;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__user__name, .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__user__role {
        display: none;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__banner {
        display: none;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__submenu--active {
        background: $accent-color-2;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__submenu > .air__menuLeft__list {
        display: none !important;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__link > span {
        display: none;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__link:before {
        right: 1.2rem;
        top: 1.4rem;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__link:after {
        right: 1.2rem;
        top: 1.2rem;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__icon {
        width: 2.66rem;
        text-align: center;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__category {
        text-indent: -999px;
    }
    .air__menu--toggled:not(.air__menu--compact) .air__menuLeft__category:after {
        display: block;
    }
}

@media (min-width: 768px) {
    .air__menu--compact .air__menuLeft {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 150px;
        -ms-flex: 0 0 150px;
        flex: 0 0 150px;
        max-width: 150px;
        min-width: 150px;
        width: 150px;
    }
    .air__menu--compact .air__menuLeft__outer {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 150px;
        -ms-flex: 0 0 150px;
        flex: 0 0 150px;
        max-width: 150px;
        min-width: 150px;
        width: 150px;
    }
    .air__menu--compact .air__menuLeft__logo {
        text-align: center;
    }
    .air__menu--compact .air__menuLeft__logo img {
        float: none;
    }
    .air__menu--compact .air__menuLeft__logo__name, .air__menu--compact .air__menuLeft__logo__descr {
        display: none;
    }
    .air__menu--compact .air__menuLeft__toggleButton {
        display: none;
    }
    .air__menu--compact .air__menuLeft__user {
        height: auto;
        text-align: center;
    }
    .air__menu--compact .air__menuLeft__user__avatar {
        float: none;
        margin-bottom: 0.66rem;
    }
    .air__menu--compact .air__menuLeft__user__name, .air__menu--compact .air__menuLeft__user__role {
        display: block;
        margin-left: 0;
    }
    .air__menu--compact .air__menuLeft__banner {
        display: none;
    }
    .air__menu--compact .air__menuLeft__submenu--active {
        background: $accent-color-2;
    }
    .air__menu--compact .air__menuLeft__submenu > .air__menuLeft__list {
        display: none !important;
    }
    .air__menu--compact .air__menuLeft__link {
        text-align: center;
        padding: 0.6rem 1.33rem;
    }
    .air__menu--compact .air__menuLeft__link > span {
        display: block;
        float: none !important;
    }
    .air__menu--compact .air__menuLeft__link > span.badge {
        display: inline-block;
    }
    .air__menu--compact .air__menuLeft__link:before {
        right: 1.2rem;
        top: 50%;
        margin-top: 2px;
    }
    .air__menu--compact .air__menuLeft__link:after {
        right: 1.2rem;
        top: 50%;
        margin-top: -1px;
    }
    .air__menu--compact .air__menuLeft__icon {
        width: 2.66rem;
        display: block;
        margin: 0 auto 0.33rem;
        text-align: center;
    }
    .air__menu--compact .air__menuLeft__category {
        display: none;
    }
}

@media (min-width: 768px) {
    .air__menu--nomenu .air__menuLeft {
        display: none !important;
    }
}

.air__menu--unfixed .air__menuLeft__outer {
    position: static;
}

.air__menu--shadow .air__menuLeft {
    -webkit-box-shadow: 0 0 100px -30px rgba(57, 55, 73, 0.3);
    box-shadow: 0 0 100px -30px rgba(57, 55, 73, 0.3);
}

.air__menu--blue .air__menuLeft {
    background: $accent-color-2;
}

.air__menu--blue .air__menuLeft__toggleButton span:first-child:before, .air__menu--blue .air__menuLeft__toggleButton span:first-child:after {
    background: #fff;
}

.air__menu--blue .air__menuLeft__toggleButton span:last-child:before, .air__menu--blue .air__menuLeft__toggleButton span:last-child:after {
    background: #fff;
}

.air__menu--blue .air__menuLeft__category:after {
    background: rgba(242, 244, 248, 0.1);
}

.air__menu--blue .air__menuLeft__link {
    color: #e4e9f0;
}

.air__menu--blue .air__menuLeft__link:hover {
    color: #fff;
}

.air__menu--blue .air__menuLeft__link:hover:before, .air__menu--blue .air__menuLeft__link:hover:after {
    background: #fff !important;
}

.air__menu--blue .air__menuLeft__submenu--active {
    background: #184ccc;
}

.air__menu--blue .air__menuLeft__submenu--active > .air__menuLeft__link {
    color: #fff;
}

.air__menu--blue .air__menuLeft__submenu--active > .air__menuLeft__link:before, .air__menu--blue .air__menuLeft__submenu--active > .air__menuLeft__link:after {
    background: #fff !important;
}

.air__menu--blue .air__menuLeft__submenu > .air__menuLeft__link:before, .air__menu--blue .air__menuLeft__submenu > .air__menuLeft__link:after {
    background: $accent-color-3;
}

.air__menu--blue .air__menuLeft__category, .air__menu--blue .air__menuLeft__user__role {
    color: $accent-color-3;
}

.air__menu--blue .air__menuLeft__banner {
    -webkit-box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.3);
}

.air__menu--gray .air__menuLeft {
    background: #f9f9f9;
}

.air__menu--gray .air__menuLeft__category:after {
    background: #e5e9f1;
}

.air__menu--gray .air__menuLeft__link {
    color: $accent-color-2;
}

.air__menu--gray .air__menuLeft__link:hover {
    color: #fff;
    background: $accent-color-2;
}

.air__menu--gray .air__menuLeft__link:hover:before, .air__menu--gray .air__menuLeft__link:hover:after {
    background: #fff !important;
}

.air__menu--gray .air__menuLeft__item--active .air__menuLeft__link {
    color: #fff;
}

.air__menu--gray .air__menuLeft__submenu--active {
    background: #e4e9f0;
}

.air__menu--gray .air__menuLeft__submenu--active > .air__menuLeft__link .air__menuLeft__icon {
    color: $accent-color-2;
}

.air__menu--gray .air__menuLeft__submenu--active > .air__menuLeft__link:hover .air__menuLeft__icon {
    color: #fff;
}

.air__menu--toggled.air__menu--gray .air__menuLeft__submenu--active {
    background: #e4e9f0 !important;
}

.air__menu--gray .air__menuLeft__submenu > .air__menuLeft__link:before, .air__menu--gray .air__menuLeft__submenu > .air__menuLeft__link:after {
    background: $accent-color-3;
}

.air__menu--gray .air__menuLeft__category, .air__menu--gray .air__menuLeft__user__name {
    color: $accent-color-2;
}

.air__menu--gray .air__menuLeft__user__role {
    color: $accent-color-3;
}

.air__menu--gray .air__menuLeft__logo__name {
    color: #161537;
}

.air__menu--gray .air__menuLeft__banner {
    -webkit-box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.3);
}

.air__menu--white .air__menuLeft {
    background: #fff;
}

.air__menu--white .air__menuLeft__category:after {
    background: #f2f4f8;
}

.air__menu--white .air__menuLeft__link {
    color: $accent-color-2;
}

.air__menu--white .air__menuLeft__link:hover {
    color: #fff;
    background: $accent-color-2;
}

.air__menu--white .air__menuLeft__link:hover:before, .air__menu--white .air__menuLeft__link:hover:after {
    background: #fff !important;
}

.air__menu--white .air__menuLeft__item--active .air__menuLeft__link {
    color: #fff;
}

.air__menu--white .air__menuLeft__submenu--active {
    background: #f2f4f8;
}

.air__menu--white .air__menuLeft__submenu--active > .air__menuLeft__link .air__menuLeft__icon {
    color: $accent-color-2;
}

.air__menu--white .air__menuLeft__submenu--active > .air__menuLeft__link:hover .air__menuLeft__icon {
    color: #fff;
}

.air__menu--toggled.air__menu--white .air__menuLeft__submenu--active {
    background: #f2f4f8 !important;
}

.air__menu--white .air__menuLeft__submenu > .air__menuLeft__link:before, .air__menu--white .air__menuLeft__submenu > .air__menuLeft__link:after {
    background: $accent-color-3;
}

.air__menu--white .air__menuLeft__category, .air__menu--white .air__menuLeft__user__name {
    color: $accent-color-2;
}

.air__menu--white .air__menuLeft__user__role {
    color: $accent-color-3;
}

.air__menu--white .air__menuLeft__logo__name {
    color: #161537;
}

.air__menu--white .air__menuLeft__banner {
    -webkit-box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
    .air__menu--flyout:not(.air__menu--toggled) .air__menuLeft__submenu--active {
        background: $accent-color-2;
    }
    .air__menu--flyout:not(.air__menu--toggled) .air__menuLeft__submenu--active > .air__menuLeft__link {
        color: #fff;
    }
    .air__menu--flyout:not(.air__menu--toggled) .air__menuLeft__submenu--active > .air__menuLeft__link .air__menuLeft__icon {
        color: #fff;
    }
    .air__menu--flyout:not(.air__menu--toggled) .air__menuLeft__submenu > .air__menuLeft__list {
        display: none !important;
    }
    .air__menu--flyout:not(.air__menu--toggled) .air__menuLeft__link:before {
        right: 1.2rem;
        top: 50%;
    }
    .air__menu--flyout:not(.air__menu--toggled) .air__menuLeft__link:after {
        right: 1.2rem;
        top: 50%;
        margin-top: -3px;
    }
    .air__menu--flyout .air__menuLeft__link:hover, .air__menu--compact .air__menuLeft__link:hover, .air__menu--toggled .air__menuLeft__link:hover {
        background: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--black .air__menuLeft__link:hover, .air__menu--compact.air__menu__submenu--black .air__menuLeft__link:hover, .air__menu--toggled.air__menu__submenu--black .air__menuLeft__link:hover {
        background: #161537;
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuLeft__link:hover, .air__menu--compact.air__menu__submenu--gray .air__menuLeft__link:hover, .air__menu--toggled.air__menu__submenu--gray .air__menuLeft__link:hover {
        background: #f2f4f8;
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuLeft__link:hover:before, .air__menu--flyout.air__menu__submenu--gray .air__menuLeft__link:hover:after, .air__menu--compact.air__menu__submenu--gray .air__menuLeft__link:hover:before, .air__menu--compact.air__menu__submenu--gray .air__menuLeft__link:hover:after, .air__menu--toggled.air__menu__submenu--gray .air__menuLeft__link:hover:before, .air__menu--toggled.air__menu__submenu--gray .air__menuLeft__link:hover:after {
        background: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuLeft__link:hover, .air__menu--compact.air__menu__submenu--white .air__menuLeft__link:hover, .air__menu--toggled.air__menu__submenu--white .air__menuLeft__link:hover {
        background: #fff;
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuLeft__link:hover:before, .air__menu--flyout.air__menu__submenu--white .air__menuLeft__link:hover:after, .air__menu--compact.air__menu__submenu--white .air__menuLeft__link:hover:before, .air__menu--compact.air__menu__submenu--white .air__menuLeft__link:hover:after, .air__menu--toggled.air__menu__submenu--white .air__menuLeft__link:hover:before, .air__menu--toggled.air__menu__submenu--white .air__menuLeft__link:hover:after {
        background: $accent-color-2;
    }
}

.air__menuFlyout {
    position: fixed;
    z-index: 3000;
    top: 100px;
    left: 300px;
    -webkit-box-shadow: 0 0 40px -10px rgba(22, 21, 55, 0.4);
    box-shadow: 0 0 40px -10px rgba(22, 21, 55, 0.4);
    background: $accent-color-2;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: -webkit-transform 0.1s ease-in-out;
    transition: -webkit-transform 0.1s ease-in-out;
    -o-transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out;
    transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.air__menuFlyout--animation {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.air__menuFlyout .air__menuLeft__list {
    max-width: 36rem;
    display: block !important;
    height: auto !important;
}

.air__menuFlyout .air__menuLeft__item {
    float: left;
    width: 12rem;
    border-left: 1px solid rgba(242, 244, 248, 0.1);
    border-bottom: 1px solid rgba(242, 244, 248, 0.1);
}

.air__menuFlyout .air__menuLeft__item:nth-child(3n + 1) {
    border-left: none;
}

.air__menuFlyout .air__menuLeft__item--active .air__menuLeft__link {
    background: rgba(242, 244, 248, 0.1);
}

.air__menuFlyout .air__menuLeft__link {
    color: #fff;
    padding: 1rem 2rem;
}

.air__menuFlyout .air__menuLeft__link:hover {
    background: rgba(242, 244, 248, 0.1);
}

.air__menuFlyout .air__menuLeft__link > span {
    display: block !important;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (min-width: 768px) {
    .air__menu--flyout.air__menu__submenu--black .air__menuFlyout,
    .air__menu--compact.air__menu__submenu--black .air__menuFlyout {
        background: #161537;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuLeft__link:hover:before, .air__menu--flyout.air__menu__submenu--white .air__menuLeft__link:hover:after,
    .air__menu--compact.air__menu__submenu--white .air__menuLeft__link:hover:before,
    .air__menu--compact.air__menu__submenu--white .air__menuLeft__link:hover:after {
        background: $accent-color-2 !important;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuLeft__submenu--active > .air__menuLeft__link:hover,
    .air__menu--compact.air__menu__submenu--white .air__menuLeft__submenu--active > .air__menuLeft__link:hover {
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuLeft__submenu--active > .air__menuLeft__link:hover .air__menuLeft__icon,
    .air__menu--compact.air__menu__submenu--white .air__menuLeft__submenu--active > .air__menuLeft__link:hover .air__menuLeft__icon {
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuFlyout,
    .air__menu--compact.air__menu__submenu--white .air__menuFlyout {
        background: #fff;
        -webkit-box-shadow: 0 4px 38px 0 rgba(22, 21, 55, 0.11), 0 0 21px 0 rgba(22, 21, 55, 0.05);
        box-shadow: 0 4px 38px 0 rgba(22, 21, 55, 0.11), 0 0 21px 0 rgba(22, 21, 55, 0.05);
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuFlyout .air__menuLeft__item,
    .air__menu--compact.air__menu__submenu--white .air__menuFlyout .air__menuLeft__item {
        border-left: 1px solid #f2f4f8;
        border-bottom: 1px solid #f2f4f8;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuFlyout .air__menuLeft__link,
    .air__menu--compact.air__menu__submenu--white .air__menuFlyout .air__menuLeft__link {
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--white .air__menuFlyout .air__menuLeft__link:hover,
    .air__menu--compact.air__menu__submenu--white .air__menuFlyout .air__menuLeft__link:hover {
        color: #1644b5;
        background: rgba(221, 226, 236, 0.3);
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuLeft__link:hover:before, .air__menu--flyout.air__menu__submenu--gray .air__menuLeft__link:hover:after,
    .air__menu--compact.air__menu__submenu--gray .air__menuLeft__link:hover:before,
    .air__menu--compact.air__menu__submenu--gray .air__menuLeft__link:hover:after {
        background: $accent-color-2 !important;
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuLeft__submenu--active > .air__menuLeft__link:hover,
    .air__menu--compact.air__menu__submenu--gray .air__menuLeft__submenu--active > .air__menuLeft__link:hover {
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuLeft__submenu--active > .air__menuLeft__link:hover .air__menuLeft__icon,
    .air__menu--compact.air__menu__submenu--gray .air__menuLeft__submenu--active > .air__menuLeft__link:hover .air__menuLeft__icon {
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuFlyout,
    .air__menu--compact.air__menu__submenu--gray .air__menuFlyout {
        background: #f2f4f8;
        -webkit-box-shadow: 0 4px 38px 0 rgba(22, 21, 55, 0.11), 0 0 21px 0 rgba(22, 21, 55, 0.05);
        box-shadow: 0 4px 38px 0 rgba(22, 21, 55, 0.11), 0 0 21px 0 rgba(22, 21, 55, 0.05);
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__item,
    .air__menu--compact.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__item {
        border-left: 1px solid #e5e9f1;
        border-bottom: 1px solid #e5e9f1;
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__item--active .air__menuLeft__link,
    .air__menu--compact.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__item--active .air__menuLeft__link {
        background: rgba(221, 226, 236, 0.3);
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__link,
    .air__menu--compact.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__link {
        color: $accent-color-2;
    }
    .air__menu--flyout.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__link:hover,
    .air__menu--compact.air__menu__submenu--gray .air__menuFlyout .air__menuLeft__link:hover {
        color: #1644b5;
        background: rgba(221, 226, 236, 0.3);
    }
}
/* FOOTER */
.air__footer {
    max-width: 85.33rem;
    margin: 0 auto;
    font-size: 0.93rem;
    padding: 0 2rem;
}

@media (max-width: 767px) {
    .air__footer {
        padding: 0 0.66rem !important;
    }
}

.air__footer__inner {
    border-top: 1px solid #e4e9f0;
    padding: 2rem 0;
}

.air__footer__logo {
    padding: 1rem 1.33rem;
    line-height: 1;
    height: 2.2rem;
    float: right;
}

@media (max-width: 767px) {
    .air__footer__logo {
        float: none;
    }
}

.air__footer__logo img {
    float: left;
    margin-top: 0.33rem;
}

.air__footer__logo__name {
    font-weight: 900;
    color: #161537;
    font-size: 21px;
    margin-left: 2.66rem;
}

.air__footer__logo__descr {
    color: #c3bedc;
    margin-left: 2.66rem;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
