//@import url( system/style.css );
/* THEME STYLE */
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import "variables";
@import "colors";
@import "layout_admin";
@import "auth";

html {
    font-size: 15px;
}

@media (max-width: 767px) {
    html {
        font-size: 14px;
    }
}
body {
    font-size: 1rem;
    line-height: 1.5;
    font-family: $font-family;
    overflow-x: hidden;
    position: relative;
}

a {
    text-decoration: none;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
}

input {
    outline: none !important;
}

button,
input {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

input.posible-inversion {
    margin-left: 0;
}
label.posibleinversion {
    padding-left: 1.5rem;
}

label{
    color: #4f81bc;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.prices {
    margin: 0;
    padding: 0;
}

.loading {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDFweCIgIGhlaWdodD0iNDFweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciPiAgICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBuZy1hdHRyLXN0cm9rZT0ie3tjb25maWcuY29sb3J9fSIgbmctYXR0ci1zdHJva2Utd2lkdGg9Int7Y29uZmlnLndpZHRofX0iIG5nLWF0dHItcj0ie3tjb25maWcucmFkaXVzfX0iIG5nLWF0dHItc3Ryb2tlLWRhc2hhcnJheT0ie3tjb25maWcuZGFzaGFycmF5fX0iIHN0cm9rZT0iIzAxOTBmZSIgc3Ryb2tlLXdpZHRoPSIxMCIgcj0iMzUiIHN0cm9rZS1kYXNoYXJyYXk9IjE2NC45MzM2MTQzMTM0NjQxNSA1Ni45Nzc4NzE0Mzc4MjEzOCIgdHJhbnNmb3JtPSJyb3RhdGUoODQgNTAgNTApIj4gICAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgY2FsY01vZGU9ImxpbmVhciIgdmFsdWVzPSIwIDUwIDUwOzM2MCA1MCA1MCIga2V5VGltZXM9IjA7MSIgZHVyPSIxcyIgYmVnaW49IjBzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPiAgICA8L2NpcmNsZT4gIDwvc3ZnPg==);
}
.breadcrumb {
    background: none;
}

.navbar {
    border-bottom: 1px solid #afbac5;
}

.info-bar {
    border-radius: 5px;
    margin: 4rem 0;
}

.card {
    border: 1px solid $border-light;
    .card-header {
        border-bottom: 1px solid $border-light;
    }
}

.notification-alert {
    margin-top: 15px;
}

/*paginacion de los ngtable*/
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}
.pagination {
    float: right;
}
.pagination > li {
    float: left;
    padding: 2px 10px;
    text-decoration: none;
    border: 1px solid #ddd;
}

.pagination {
    li:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    li:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
.ng-table-counts {
    visibility: hidden;
}
.ng-table-header {
    color: white;
}
/*fin*/
/* alineacion en tablas */
.table {
    td,
    th {
        vertical-align: middle !important;
    }
}
/* carrucel home page*/
.carousel-caption h5 {
    font-weight: bold;
}

.property-form {
    .react-tabs__tab {
        /* padding: 2px 10px;*/
        h5 {
            margin: 0px;
            color: black;
        }
    }
    .react-tabs__tab-list {
        margin-bottom: 0.1rem;
    }
    .react-tabs__tab--selected{
        h5 {
            color: white;
        }
    }
    .ck-editor__editable_inline {
        min-height: 200px;
        max-height: 300px;
    }
}

.editor-fixed-size{
    .ck-editor__editable_inline {
        min-height: 200px;
        max-height: 300px;
    }
}

.react-tabs__tab--selected{
    background: #4f81c2;
    border-color: #4f81c2;
    color: white;
    h5 {
        color: white;
    }
}

.is-invalid {
    .ck.ck-editor__main>.ck-editor__editable{
        border-color: #dc3545!important;
    }
    .ck.ck-toolbar{
        border-color: #dc3545!important;
    }
}

.fileContainer {
    margin: auto auto;
    padding: 0 0 20px 0;

    .uploadPictureContainer {
        width: 23%;
        margin: 0.5em;
        padding: 10px;
    }
}

.modal-body .react-tabs__tab-panel {
    overflow-y: scroll;
    height: 300px;

    @media screen and (max-height: 520px) {
        height: 150px;
    }
    @media screen and (min-height: 769px) {
        height: 465px;
    }
}

.modal-noticia {
    .react-tabs__tab-panel {
        overflow-y: scroll;
        height: 300px;

        @media screen and (max-height: 520px) {
            height: 100px;
        }
    }
}
.content-modal-mobile {
    @media screen and (max-height: 520px) {
        overflow-y: scroll;
        height: 200px;
    }
}

/** MODAL **/
.ReactModalPortal {
    position: absolute;
    z-index: 999;

    .ReactModal__Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .modal-contact-user {
        font-size: 1rem;
        .atts {
            font-weight: 700;
        }
        .atts-values {
            font-weight: lighter;
        }
        .row-values {
            border-bottom: 1px solid $lightgrey;
            padding: 0.5rem 0;
        }
    }
}
.loading-circle {
    display: flex;
    justify-content: center;
}

.react-fancybox {
    .thumbnail {
        width: 100px;
        img {
            width: 50px;
            border: 3px solid $accent-color-2;
        }
    }
}
.videoImg {
    div {
        a {
            img {
                width: 50px;
                border: 3px solid $accent-color-2;
            }
        }
    }
}

.MuiIconButton-root {
    flex: 0 0 auto;
    color: #1c666f !important;
    padding: 12px !important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50% !important;
}

.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.MuiButtonBase-root:hover {
    background: rgba(0, 0, 0, 0.04);
}

.stuck-message{
    position : relative;
    
}

.page-link{
    border : 0px;
}


.btn-take-action {
    color: #ffe484;
    border-color: #ffe484;
}

.btn-evolving {
    color: #ffe484;
    border-color: #ffe484;
    background-color: #17a2b8;
}
.btn-waiting-response {
    color: #ffe484;
    border-color: #ffe484;
    background-color: #17a2b8;
}
.btn-pending {
    color: #ffe484;
    border-color: #ffe484;
    background-color: #17a2b8;
}
.btn-show-all {
    color: #ffe484;
    border-color: #ffe484;
    background-color: #17a2b8;
}
.btn-closed {
    color: #ffe484;
    border-color: #ffe484;
    background-color: #17a2b8;
}

.btn-closed {
    color: #ffe484;
    border-color: #ffe484;
    background-color: #17a2b8;
}


@media (min-width: 769px) {
.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}

}

@media (min-width: 1025px) {
    .modal-dialog {
        max-width: 900px;
        margin: 1.75rem auto;
    }
    
    }


