//TIPOGRAFIA
 $font-family: 'Lato', sans-serif;
 $font-weight-bold: 700;
 $font-weight-regular: 400;
 $font-weight-light: 300;

//COLORES
// colores principales segun la identidad corporativa del negocio:
 $accent-color-1: #1c666f;
 $accent-color-2: #4f81bc;
 $accent-color-3: #93ccdd;

 $color-special-1: #18646D;
 $lightgrey: #f9f9f9;
 $border-light: #efefef;

 $gray: #2e2e2e;
 $elegant-color-dark: #212121;
 $success: #46be8a;