/*  "COLORS" STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $accent-color-2;
}
input {
    color: $accent-color-2;
}
a {
    color: $accent-color-2;
}

a:hover,
a:active,
a:focus {
    color: $accent-color-3;
}
.table {
    .thead-dark {
        th {
            background-color: $accent-color-3;
            border-color: $accent-color-3;
            color:$accent-color-1;
        }
    }

}
.text-red {
    color:red!important;
}
.btn-primary {
    background-color:  $accent-color-1;
    border-color:  $accent-color-1;
}
.btn-secondary {
    background-color:  $accent-color-2;
    border-color:  $accent-color-2;
}
.loading{
    background-color: $accent-color-3;
}
.navbar {
    color:$accent-color-2;
    background:#f3f3f3;
}
.info-bar {
    background: #f1f1f1;
}
.footer-copyright a {
    color:$accent-color-3;
}
.pagination>li>a {
    color: #192a56 !important;
}
.pagination li.active {
    background-color: #dfe6e9;
}
.ng-table-sort-header {
    background: $accent-color-2;
}
/* carrucel home page*/
.carousel-caption {
    background: rgba(0,0,0,0.5);
}
.carousel-caption h5 {
    color:white!important;
}
.far {
    color: green;
}
//*************************** colores background
.bg-accent-color-1 {
    background-color: $accent-color-1 !important;
    color:white!important;
}

.bg-accent-color-2 {
    background-color: $accent-color-2!important;
    color:white!important;
}

.text-primary {
    color: $accent-color-1 !important;
}
.text-secondary {
    color: $accent-color-2 !important;
}

.text-default {
    color: $gray !important;
}

.text-success {
    color: $success !important;
}

.elegant-color {
    background-color: $gray !important;
}

.elegant-color-dark {
    background-color: $elegant-color-dark !important;
}

